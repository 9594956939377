import React from "react";
import { Card, Selection, SmallHeader, Snap } from "@milana/web-client";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { gotoSignInPage, gotoSignUpPage } from "../utils/authutils.js";
import { config } from "../settings";
import Grid from "@mui/material/Grid";
import CookieConsent from "react-cookie-consent";
import Chatwoot from "../../src/components/chatWoot.jsx";
import LoginIcon from "@mui/icons-material/Login";
import { useTheme } from "@mui/material/styles";

const SnapDiv = styled.div`
	text-align: center;
	max-height: 18vh;
`;

const MainCard = styled.div`
	min-height: 95vh;
	display: grid;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	@media (min-width: 700px) {
		width: 700px;
		border: 20px solid #e6e8eb;
		border-radius: 30px;
	}
	padding: 0 !important;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-left: 25px;
`;

const CustomButton = styled(Selection)`
	height: 40px;
	border-radius: 15px;
	width: 50%;
	@media (max-width: 700px) {
		width: 100%;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 600px) {
		flex-direction: column;
	}
`;

const HeaderTextContainer = styled.div`
	text-align: center;
	margin: 20px 0;
`;

const AppName = styled.h1`
	font-size: 3em;
	color: #333;
	margin-bottom: 10px;
	font-weight: bold;
`;

const Tagline = styled.h3`
	font-size: 1.5em;
	color: #555;
	margin-bottom: 10px;
`;

const FooterTextContainer = styled.div`
	background-color: #fafafa;
	margin-top: 10px;
	padding: 10px;
	padding-bottom: 60px;
	margin-left: 20px;
`;

const Description = styled.div`
	font-size: 1.2em;
	color: #777;
	text-align: justify;
	margin-left: 10px;
`;

const HighlightedText = styled.h4`
	color: #777;
	font-size: 1.2em;
`;

const FooterText = styled.div`
	font-size: 10px;
	text-align: left;
	background-color: #fafafa;
	margin-bottom: 10px;
`;

const FooterContactInfo = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	gap: 10px;
`;

const openChatwoot = () => {
	if (window.$chatwoot) {
		window.$chatwoot.toggle();
	}
};

const Landing = () => {
	const theme = useTheme();
	const primaryColor = theme.palette.primary.main;

	const HeaderProps = {
		rightLogo: config.partnerLogo,
		leftLogo: config.brandLogo,
		backgroundColor: "",
		iconColor: "primary",
		isBackButton: false,
		isTabButton: false,
		heading: "Welcome",
		headingBold: true,
		headingColor: "primary",
		fixFullScreen: true,
	};

	const WelcomeProps = {
		height: "20vh",
		shadow: false,
		snap: config.welcomeImage,
		mainLogoAlt: "",
		footerLogoAlt: "",
	};

	const SignUPButtonProps = {
		label: "SIGN UP Free",
		variant: "contained",
		fullWidth: true,
		size: "small",
		className: "custom-button",
		style: { fontSize: "20px" },
	};

	const SignINButtonProps = {
		label: "SIGN IN",
		variant: "contained",
		color: "primary",
		fullWidth: true,
		size: "small",
		className: "custom-button",
		style: { fontSize: "20px" },
		endIcon: <LoginIcon />,
	};

	const TextCardProps = {
		backgroundColor: "",
		textColor: "primary",
		borderRadius: "20",
		boxBorder: false,
		width: "95%",
		children: (
			<HeaderTextContainer>
				<AppName>Milana</AppName>
				<Tagline>Connecting values....globally</Tagline>
				<HighlightedText>
					Matrimonial platform to assist in connecting life partner
				</HighlightedText>
			</HeaderTextContainer>
		),
	};

	const PricingCardProps = {
		backgroundColor: "",
		textColor: "#777",
		borderRadius: "20",
		boxBorder: false,
		width: "95%",
		children: (
			<h5>
				<Description>
					Sign up free and get your first 10 mutual connections at no cost.
					Pricing depends on mutual connections — where both users accept each
					other's interest. Contact us for details.
				</Description>
			</h5>
		),
	};

	return (
		<MainCard>
			<SmallHeader {...HeaderProps} />
			<SnapDiv>
				<Snap {...WelcomeProps} />
			</SnapDiv>
			<Card centerContent={true} {...TextCardProps} />
			<Card width={"95%"}>
				<ButtonContainer>
					<CustomButton {...SignUPButtonProps} onClick={gotoSignUpPage} />
				</ButtonContainer>
				<br />
				<ButtonContainer>
					<CustomButton {...SignINButtonProps} onClick={gotoSignInPage} />
				</ButtonContainer>
				<Card centerContent={true} {...PricingCardProps} />
				<div
					style={{
						fontSize: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<button
						onClick={openChatwoot}
						style={{
							backgroundColor: primaryColor,
							color: "#fff",
							border: "none",
							padding: "10px 20px",
							borderRadius: "20	px",
							cursor: "pointer",
						}}
					>
						Chat with Us
					</button>
				</div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FooterTextContainer>
							<FooterText>
								<p>
									We value your privacy and security. Your personal data is
									collected solely for the purpose of finding suitable matches
									and providing marriage-related services. Rest assured, your
									information is kept secure and is shared only with authorized
									third parties when necessary. For more details, please review
									our
									<a> </a>
									<a href="/privacy-notice-v0.1.html" target="_blank">
										Privacy Notice
									</a>
									<a> and </a>
									<a href="/TandC_v0.1.html" target="_blank">
										Terms and Conditions
									</a>
									.
								</p>
							</FooterText>
							<FooterContactInfo>
								<img
									src="https://cdn-milana.azureedge.net/photos/PREDICTIVE_RESEARCH_Logo.jpg"
									alt="Predictive Research Logo"
									style={{
										width: "100px",
										backgroundColor: "#FAFAFA",
									}}
								/>
								<div
									style={{
										fontSize: "10px",
										textAlign: "left",
										backgroundColor: "#FAFAFA",
									}}
								>
									<p>
										MILANA is a product of Predictive Research Pty Ltd (ABN 79
										663 533 944). For any inquiries, please contact us at{" "}
										<a href="mailto:support@milana.tech">support@milana.tech</a>{" "}
										or call us at +61 423 706 407.
									</p>
								</div>
							</FooterContactInfo>
						</FooterTextContainer>
					</Grid>
				</Grid>
				<CookieConsent
					location="bottom"
					cookieName="milanaNecessaryCookies"
					style={{
						backgroundColor: "#FAFAFA",
						border: "1px solid #f5f5f5",
						maxWidth: "700px",
						margin: "0 auto",
						position: "fixed",
						bottom: "20px",
						left: "50%",
						transform: "translateX(-50%)",
						color: "#000000",
					}}
					buttonStyle={{
						backgroundColor: "#F16E26",
						color: "#FFFFFF",
						fontSize: "13px",
						left: "20%",
					}}
					containerClasses="alert alert-warning col-lg-12"
					buttonText="Accept"
					overlay
					expires={365}
				>
					<div style={{ textAlign: "center" }}>
						We use necessary cookies to enhance your user experience.
					</div>
				</CookieConsent>
			</Card>
			<Chatwoot />
		</MainCard>
	);
};

Landing.propTypes = {
	children: PropTypes.node,
};

export default Landing;
